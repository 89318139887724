.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin: 0;
    padding: 40px;
}

h1 span {
    font-size: 30px;
}

.lopez {
    text-decoration: none;
    color: chartreuse;
}

.floating-icon {
    background-color: var(--light-blue) ;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    cursor: pointer;
}

.floating-icon a {
    border: 1px solid #fff;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mouse {
    transform: translateY(-25%);
    animation: bounce .8s ease infinite alternate;
}

@keyframes bounce {
    0% {
        transform: translateY(-25%);
    }
    100% {
        transform: translateY(25%);
    }
}