@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --dark-text: #333;
  --form-button: #2da5ff;
  --color-grey: #eae6eb;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
}

section {
  width: 100%;
  padding: 8rem 0;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 10px;
}

/* Text styles */
.u-text-small {
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 300;
}

.u-text-dark {
  color: var(--dark-text);
}
.u-text-light {
  color: var(--color-white);
}

.u-text-center {
  text-align: center;
}

/* Title */
.u-title {
  text-align: center;
  margin-bottom: 4rem;
}

.u-title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.u-title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}
