section#table {
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%,
        rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
}

.detailsPage {
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%,
    rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%); 
}

.my-custom-class {
    background-color: aqua;
}

.title {
    text-align: center;
    margin-bottom: 4rem;
}

.title h2 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--color-white);
    margin: 1.5rem 0;
}

.container-table {
    margin: 10px;
    padding: 10px;
}

.Mui-TableHeadCell-Content-Wrapper {
    font-size: 1.875rem;
    max-width: fit-content;
}

.features-content {
    display: flex;
    justify-content: center;
}

.features-left {
    flex: 1;
    text-align: center;
}

.features-left img {
    width: 65%;
}

.features-right {
    flex: 1;
}

.search-button {
    width: 100px;
    height: 30px;
    background-color: orangered;
    color: var(--color-white);
    border: 2px solid transparent;
    border-radius: 5px;
    margin-left: 1rem;
    font-weight: 500;
    padding: 5px 8px;
}

.legend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 10px; */
    width: 100%;
    color: white; /* set text color to white */
}

.legend ul {
    list-style-type: none;
  }
  
.legend li {
    margin-bottom: 5px;
    color: white;
  }
  
.legend-key {
    display: inline-block;
    margin-right: 5px;
    background-color: #d9edf7;
    color: black;
    font-size: 15px;
    padding: 3px 5px;
    border-radius: 3px;
}

.legend-column-left {
    float: left;
    width: 50%;
}

.legend-column-right {
    float: right;
    width: 50%;
}

table {
    width: 100%;
    margin-left: 10px;
}


th, td {
    font-size: 15px;
    padding: 10px;
    text-align: left;
    border: 1px solid transparent;
    color: white;
}
  